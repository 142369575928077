import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import BubbleSVG from './quote.svg';

interface FleetAssistantQuoteProps {
    onClose: (p: { doNotShowAgain: boolean }) => void;
    isChecked: boolean;
    onCheckboxChange: (checked: boolean) => void;
}

const quoteContainerStyle: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 10,
    width: '100%',
    height: '100%',
    padding: '15px',
};

const quoteSvgContainerStyle: React.CSSProperties = { position: 'relative', width: '470px' };

const quoteSvgStyle: React.CSSProperties = { width: '100%', height: 'auto' };

const quoteButtonStyle: React.CSSProperties = {
    background: 'none',
    border: 'none',
    color: 'white',
    fontSize: '15px',
    cursor: 'pointer',
};

const body: React.CSSProperties = { height: '80%' };
const footer: React.CSSProperties = { position: 'absolute', bottom: 0 };

const FleetAssistantQuote: React.FC<FleetAssistantQuoteProps> = ({ onClose, isChecked, onCheckboxChange }) => {
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onCheckboxChange(event.target.checked);
    };

    const handleClose = () => {
        onClose({ doNotShowAgain: isChecked });
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose({ doNotShowAgain: isChecked });
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose, isChecked]);

    const QuoteHeader = (
        <p className="text-color-white text-size-16 text-medium">
            <FormattedMessage id={'fleetAssistantWhiteOverlay.title'} />{' '}
        </p>
    );

    const QuoteBody = (
        <p className="text-color-white text-size-16 text-medium">
            <FormattedMessage id={'fleetAssistantWhiteOverlay.body'} />{' '}
        </p>
    );

    const QuoteFooter = (
        <div style={footer}>
            <Checkbox className="text-color-white text-size-10" onChange={handleCheckboxChange}>
                <span>
                    {' '}
                    <FormattedMessage id={'fleetAssistantWhiteOverlay.checkbox'} />{' '}
                </span>
            </Checkbox>
        </div>
    );

    const CloseButton = (
        <button onClick={handleClose} style={quoteButtonStyle}>
            <span className={'rioglyph rioglyph-remove'} />
        </button>
    );

    return (
        <div style={quoteSvgContainerStyle}>
            <img src={BubbleSVG} alt="Speech Bubble" style={quoteSvgStyle} />

            <div className="row" style={quoteContainerStyle}>
                <div className="col-11 margin-bottom-20 space-y-10" style={body}>
                    {QuoteHeader}
                    {QuoteBody}
                    {QuoteFooter}
                </div>
                <div className="col-1 margin-bottom-20">{CloseButton}</div>
            </div>
        </div>
    );
};

export default FleetAssistantQuote;
