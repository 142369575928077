import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import OnboardingTip from '@rio-cloud/rio-uikit/OnboardingTip';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

type OnOnboardingText = {
    header?: string | undefined;
    body?: string | undefined;
    footer?: string | undefined;
};

type OnOnboardingProps = {
    showOnboarding: boolean;
    showIconOnboarding: boolean;
    onOnboardingText: OnOnboardingText;
    handleOnBoardingClick: () => void;
};

const FleetAssistantOnBoarding = ({
    showOnboarding,
    showIconOnboarding,
    onOnboardingText,
    handleOnBoardingClick,
}: OnOnboardingProps) => {
    const OnBoardingTipContent = (
        <div className="row">
            {showIconOnboarding && (
                <div className="col-1 margin-bottom-20 space-y-10">
                    <span className="icon rioglyph rioglyph-robot text-size-18" />
                </div>
            )}
            <div className="col-10">
                <ul className="list-style-none padding-0 margin-0 space-y-5">
                    {onOnboardingText.header && (
                        <li className="display-flex gap-5 margin-left-0 text-medium">
                            <FormattedMessage id={onOnboardingText.header} />
                        </li>
                    )}
                    {onOnboardingText.body && (
                        <li className="display-flex gap-5 margin-left-0 text-medium">
                            <FormattedMessage id={onOnboardingText.body} />
                        </li>
                    )}
                    {onOnboardingText.footer && (
                        <li className="display-flex gap-5 margin-left-0">
                            <FormattedMessage id={onOnboardingText.footer} />
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );

    return (
        <ActionBarItem data-test="fleet-assistant-container">
            <ActionBarItem.Icon onClick={handleOnBoardingClick}>
                <div role="button" style={{ display: 'inline-block' }}>
                    <OnboardingTip
                        show={showOnboarding}
                        content={OnBoardingTipContent}
                        placement={OnboardingTip.BOTTOM}
                        onHide={handleOnBoardingClick}
                        className="z-index-max"
                        width={400} // only accepts values of 50 at a time
                    >
                        <span className="icon rioglyph rioglyph-robot" />
                    </OnboardingTip>
                </div>
            </ActionBarItem.Icon>
        </ActionBarItem>
    );
};

export default connect()(FleetAssistantOnBoarding);
