import FleetAssistantOnBoarding from '@features/fleetAssistant/FleetAssistantOnBoarding/FleetAssistantOnBoarding';
import { getFleetFlagFromLocalStorage, saveFleetFlagToLocalStorage } from '@features/ui/utils';
import { useState } from 'react';
import { connect } from 'react-redux';

export const onboardingFlagKey = 'hasSeenFleetAssistantWelcomeOnboarding';
const hasSeenOnboarding = !getFleetFlagFromLocalStorage(onboardingFlagKey);

const FleetAssistantWelcome = () => {
    const [showOnboarding, toggleOnboarding] = useState<boolean>(hasSeenOnboarding);

    const handleOnBoardingClick = () => {
        if (showOnboarding) {
            toggleOnboarding(!showOnboarding);
            saveFleetFlagToLocalStorage(onboardingFlagKey);
        }
    };

    const onOnboardingText = {
        header: 'fleetAssistant.onboarding.welcome.header',
        body: 'fleetAssistant.onboarding.welcome.body',
    };

    return (
        <FleetAssistantOnBoarding
            showOnboarding={showOnboarding}
            showIconOnboarding={false}
            onOnboardingText={onOnboardingText}
            handleOnBoardingClick={handleOnBoardingClick}
        />
    );
};

export default connect()(FleetAssistantWelcome);
