// Import application style
import './features/app/styles.less';

// Import language configuration
import { DEFAULT_LOCALE } from '@common/lang/lang';
import { getLangLocale, getLanguageData } from '@common/lang/selectors';
import { isAllowedToUseFleetAssistant, isLATAMTenant, isUserSessionExpired } from '@common/login/selectors';
import { PermissionGate } from '@common/permissions/AccessControl';
import { LegacyPermissionsType } from '@common/permissions/reducer';
import { getFeatureToggles, getLegacyPermissions } from '@common/permissions/selectors';
import { getIdToken } from '@common/tokenHandling/selectors';
import { requestDrivers, requestGroups, requestVehicles } from '@data/actions';
import { hideSessionExpiredDialog } from '@features/app/reducer';
import { getSessionExpiredAcknowledged } from '@features/app/selectors';
import ElevationGraph from '@features/environmentConditions/ElevationGraph';
import FleetAssistant from '@features/fleetAssistant/FleetAssistantDialog/FleetAssistant';
import FleetAssistantComingSoon from '@features/fleetAssistant/FleetAssistantOnBoarding/FleetAssistantComingSoon';
import FleetAssistantPhaseOne from '@features/fleetAssistant/FleetAssistantPhaseOne/FleetAssistantPhaseOne';
import Perform3Toggle from '@features/perform3Toggle/Perform3Toggle';
import ServiceInfoContainer from '@features/serviceInfo/ServiceInfoContainer';
import WelcomePage, { shouldShowWelcomePage } from '@features/serviceInfo/WelcomePage';
import Tree from '@features/tree/Tree';
import Feedback from '@features/ui/Feedback';
import { isSidebarVisible } from '@features/ui/reducer';
import { getFleetFlagFromLocalStorage } from '@features/ui/utils';
import Administration from '@pages/administration/AdministrationEntry';
import CoDriver from '@pages/coDriver/CoDriver';
import DriverSidebar from '@pages/driverAnalysis/DriverSidebar';
import DriverAnalysis from '@pages/driverAnalysis/Entry';
import ReportEntry from '@pages/reports/Entry';
import VehicleEntry from '@pages/vehicleAnalysis/Entry';
import VehicleSidepanel from '@pages/vehicleAnalysis/Sidepanel';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import IframeResizer from 'iframe-resizer-react';
import _ from 'lodash';
import moment from 'moment';
import React, { Suspense, useEffect, useState } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { matchPath, NavLink, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';

import * as routes from './constants/routes';
import { subModules } from './constants/subModules';
import { isInitialized as isMFHInitialized } from './data/selectors';
import { env } from './env';
import EditReportSidebar from './pages/reports/EditReportSidebar';
import { State } from './setup/types';

// We do not want to load the baggage of the Pdf stuff if not needed.
const PdfEntry = React.lazy(() => import('@pages/pdf/Entry'));

const { MENU_URL, FLEET_ASSISTANT_URL } = env.runtimeConfig.backend;

const {
    DRIVER_ROUTE,
    VEHICLE_ROUTE,
    ADMINISTRATION_ROUTE,
    WELCOME_ROUTE,
    CODRIVER_ROUTE,
    REPORT_ROUTE,
    EDIT_REPORT_ROUTE,
    VIEW_REPORT_ROUTE,
} = routes;

// Define the navigation items of the current application
const title = (
    <div>
        <span>
            <FormattedMessage id="moduleName" />
        </span>
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id="tooltip" className="top" width="auto">
                    {import.meta.env.COMMIT}
                </Tooltip>
            }
        >
            <span className="text-color-gray margin-left-10">v</span>
        </OverlayTrigger>
        <span className="text-color-gray">{import.meta.env.SERVICE_VERSION}</span>
    </div>
);

export const DriverVehicleAndGroupLoader = (props: {
    loadDrivers: () => void;
    loadVehicles: () => void;
    loadGroups: () => void;
}) => {
    useEffect(() => {
        props.loadDrivers();
        props.loadVehicles();
        props.loadGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

const ConnectedDriverVehicleAndGroupLoader = connect(undefined, dispatch => ({
    loadDrivers: () => dispatch(requestDrivers()),
    loadVehicles: () => dispatch(requestVehicles()),
    loadGroups: () => dispatch(requestGroups()),
}))(DriverVehicleAndGroupLoader);

const getLastRoute = () => {
    try {
        if (shouldShowWelcomePage()) {
            return WELCOME_ROUTE;
        }
        const lastRoute = localStorage.getItem('lastRoute');
        return lastRoute && Object.values(routes).some(route => matchPath(lastRoute, route))
            ? lastRoute
            : WELCOME_ROUTE;
    } catch (e) {
        return WELCOME_ROUTE;
    }
};

const route2Component: Record<string, React.FC> = {
    [VEHICLE_ROUTE]: VehicleEntry,
    [ADMINISTRATION_ROUTE]: Administration,
    [DRIVER_ROUTE]: DriverAnalysis,
    [CODRIVER_ROUTE]: CoDriver,
};

const withPermission = ({
    childProps,
    route,
}: {
    childProps: RouteComponentProps<{
        [key: string]: string | undefined;
    }>;
    route: string;
}) => React.createElement(route2Component[route], childProps as React.Attributes);

const noPermission = () => <Redirect to={WELCOME_ROUTE} />;
const noPermissionPending = () => <Spinner />;

const NULL_LOCATION_OBJ = { pathname: '' };

export const AppContainer = ({
    idToken,
    hideSessionDialog,
    homeRoute,
    languageData,
    showSessionExpired,
    location,
    userLocale: locale,
    legacyPermissions,
    isDriverVehicleSidePanelVisible,
    isInitialized,
    isLATAMTenant = false,
    showFleetAssistant,
    showFleetAssistantComingSoon,
}: {
    idToken: string;
    hideSessionDialog: () => void;
    languageData: Record<string, string>;
    homeRoute?: string;
    showSessionExpired: boolean;
    location: Location;
    userLocale: string;
    legacyPermissions: LegacyPermissionsType;
    isDriverVehicleSidePanelVisible: boolean;
    isInitialized: boolean;
    isLATAMTenant?: boolean;
    showFleetAssistant: boolean;
    showFleetAssistantComingSoon: boolean;
}) => {
    const lastRoute = getLastRoute();
    const pathname = (location || NULL_LOCATION_OBJ).pathname;
    const appTitle = <FormattedMessage id={'moduleName'} />;
    const userMenu = <DefaultUserMenu environment={import.meta.env.MODE || ''} />;
    //TODO: Remove me after 2020/06/01 tracked under: [TBMTWOPA-2870]
    const serviceInfoContainer = <ServiceInfoContainer title={title} />;
    const notifications = <RioNotifications useLocalSpa={env.featureToggles.enforcedEnv === 'localprod'} />;
    const fleetAssistant = <FleetAssistant fleetSrc={FLEET_ASSISTANT_URL} />;
    const fleetAssistantComingSoon = <FleetAssistantComingSoon />;
    const menu = <IframeResizer className="iFrameResizer" src={MENU_URL} />;

    const perform3Toggle = <Perform3Toggle />;

    const homeLink = <a href={homeRoute} />;

    const filteredSubModules = subModules
        .filter(subModule => _.get(legacyPermissions, ['submodules', subModule.legacyPermission]))
        .filter(subModule => !(isLATAMTenant && ['driver', 'coDriver', 'administration'].includes(subModule.id)));

    const navItems = filteredSubModules.map(subModule => ({
        key: subModule.id,
        route: (
            <NavLink to={subModule.route}>
                <FormattedMessage id={subModule.translationKey} />
            </NavLink>
        ),
    }));

    if (!isLATAMTenant) {
        navItems.splice(2, 0, {
            key: 'reports',
            route: (
                <NavLink to={REPORT_ROUTE}>
                    <FormattedMessage id="subModuleName.reports" />
                </NavLink>
            ),
        });
    }

    const subModuleRoutes = subModules.map(subModule => (
        <Route
            key={subModule.id}
            path={subModule.route}
            render={props => (
                <PermissionGate
                    hasPermission={_.includes(filteredSubModules, subModule)}
                    isPermissionLoaded={legacyPermissions.isLoaded}
                    components={{
                        withPermission: () => withPermission({ childProps: props, route: subModule.route }),
                        noPermission,
                        noPermissionPending,
                    }}
                />
            )}
        />
    ));

    const [showFleetAssistantPhaseOne, setShowFleetAssistantPhaseOne] = useState(
        getFleetFlagFromLocalStorage('hasSeenFleetAssistantRobot')
    );

    const showFleetWelcome = showFleetAssistant && !showFleetAssistantComingSoon;
    const showFleetComingSoon = !showFleetAssistant && showFleetAssistantComingSoon && showFleetAssistantPhaseOne;

    const actionBarItemsToBeDisplayed = [
        perform3Toggle,
        serviceInfoContainer,
        ...(showFleetWelcome ? [fleetAssistant] : []),
        ...(showFleetComingSoon ? [fleetAssistantComingSoon] : []),
        notifications,
        userMenu,
    ];

    // When loading the root path / we still don't know if the tree should be shown. So do not show w
    // it we redirect
    const isAnalyzeRoute = pathname !== '/' && (pathname?.includes(DRIVER_ROUTE) || pathname?.includes(VEHICLE_ROUTE));
    const robotFleetAssistantOverlay = showFleetAssistantComingSoon ? (
        <FleetAssistantPhaseOne showPhaseOne={setShowFleetAssistantPhaseOne} />
    ) : (
        ''
    );

    useEffect(() => {
        if (pathname !== '/' && pathname !== lastRoute && isAnalyzeRoute) {
            localStorage.setItem('lastRoute', pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <IntlProvider locale={locale} key={locale} messages={languageData} defaultLocale={DEFAULT_LOCALE}>
            <ApplicationLayout className={'PerformanceAnalysis'}>
                <ConnectedDriverVehicleAndGroupLoader />

                {robotFleetAssistantOverlay}

                <ApplicationLayout.Header>
                    <ApplicationHeader
                        label={appTitle}
                        appNavigator={menu}
                        homeRoute={homeLink}
                        navItems={navItems}
                        actionBarItems={actionBarItemsToBeDisplayed}
                    />
                </ApplicationLayout.Header>
                <ApplicationLayout.Sidebar>
                    {isAnalyzeRoute && <Tree categories={_.map(filteredSubModules, 'treeTab')} />}
                </ApplicationLayout.Sidebar>
                <ApplicationLayout.Sidebar className="right">
                    <Switch>
                        <Route
                            path={VEHICLE_ROUTE}
                            component={() => {
                                return isDriverVehicleSidePanelVisible ? <VehicleSidepanel /> : null;
                            }}
                        />
                        <Route path={DRIVER_ROUTE} component={DriverSidebar} />
                        {!isLATAMTenant && (
                            <Route path={`${EDIT_REPORT_ROUTE}/:id`} render={() => <EditReportSidebar />} />
                        )}
                    </Switch>
                </ApplicationLayout.Sidebar>
                <ApplicationLayout.Body className="responsive">
                    {isAnalyzeRoute && <Feedback />}
                    <NotificationsContainer />
                    <SessionExpiredDialog locale={locale} onClose={hideSessionDialog} show={showSessionExpired} />

                    {isInitialized ? (
                        <Switch>
                            {subModuleRoutes}
                            <Route path={WELCOME_ROUTE} component={WelcomePage} />
                            {env.featureToggles.developer ? (
                                <Route
                                    path="/elevation"
                                    component={() => (
                                        <Suspense fallback={<Spinner />}>
                                            <ElevationGraph
                                                dateRange={{
                                                    start: moment(new Date())
                                                        .subtract(10, 'days')
                                                        .toDate(),
                                                    end: new Date(),
                                                }}
                                                vehicleIds={['1']}
                                                driverIds={[]}
                                            />
                                        </Suspense>
                                    )}
                                />
                            ) : null}
                            <Route path={`${VIEW_REPORT_ROUTE}/:id`} component={ReportEntry} />
                            <Route path={REPORT_ROUTE} component={ReportEntry} />
                            {env.featureToggles.developer ? (
                                <Route
                                    path="/dev-pdf"
                                    component={() => (
                                        <Suspense fallback={<Spinner />}>
                                            <PdfEntry />
                                        </Suspense>
                                    )}
                                />
                            ) : null}
                            <Route render={() => <Redirect to={lastRoute} />} />
                        </Switch>
                    ) : (
                        <div className="display-flex justify-content-center position-relative height-100pct">
                            <span
                                style={{
                                    left: '50%',
                                    top: '50%',
                                    position: 'absolute',
                                    translate: 'transform(-50%, -50%}',
                                }}
                            >
                                <Spinner />
                            </span>
                        </div>
                    )}
                </ApplicationLayout.Body>
            </ApplicationLayout>
        </IntlProvider>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    hideSessionDialog: () => dispatch(hideSessionExpiredDialog()),
});

const mapStateToProps = (state: State) => ({
    idToken: getIdToken(state),
    homeRoute: env.runtimeConfig.homeRoute,
    languageData: getLanguageData(state),
    showSessionExpired: isUserSessionExpired(state) && !getSessionExpiredAcknowledged(state),
    userLocale: getLangLocale(state),
    legacyPermissions: getLegacyPermissions(state),
    isDriverVehicleSidePanelVisible: isSidebarVisible(state, VEHICLE_ROUTE),
    isInitialized: isMFHInitialized(state),
    isLATAMTenant: isLATAMTenant(state),
    showFleetAssistant: (getFeatureToggles(state).showFleetAssistant as boolean) && isAllowedToUseFleetAssistant(state),
    showFleetAssistantComingSoon:
        (getFeatureToggles(state).showFleetAssistantComingSoon as boolean) && isAllowedToUseFleetAssistant(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
