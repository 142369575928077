import FleetAssistantQuote from '@features/fleetAssistant/FleetAssistantPhaseOne/FleetAssistantQuote';
import FleetAssistantRobot from '@features/fleetAssistant/FleetAssistantPhaseOne/FleetAssistantRobot';
import { getFleetFlagFromLocalStorage, saveFleetFlagToLocalStorage } from '@features/ui/utils';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

export const robotFlagKey = 'hasSeenFleetAssistantRobot';
const hasSeenRobot = !getFleetFlagFromLocalStorage(robotFlagKey);

const FleetAssistantWhiteOverlayContainerStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(255,255,255,0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
};

const FleetAssistantWhiteOverlayContentStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '20px',
};

type ToggleHandler = (stateValue: boolean) => void;

const FleetAssistantPhaseOne = ({ showPhaseOne }: { showPhaseOne: ToggleHandler }) => {
    const [showWhiteOverlay, setShowWhiteOverlay] = useState<boolean>(hasSeenRobot);
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = useCallback((checked: boolean) => {
        setIsChecked(checked);
    }, []);

    const handleClose = useCallback(
        (data: { doNotShowAgain: boolean }) => {
            setShowWhiteOverlay(false);
            showPhaseOne(true);
            saveFleetFlagToLocalStorage(robotFlagKey, data.doNotShowAgain);
        },
        [showWhiteOverlay]
    );

    if (!showWhiteOverlay) return null;

    return (
        <div
            style={FleetAssistantWhiteOverlayContainerStyle}
            onClick={() => handleClose({ doNotShowAgain: isChecked })}
            className="white-overlay"
        >
            <div style={FleetAssistantWhiteOverlayContentStyle} onClick={e => e.stopPropagation()}>
                <FleetAssistantRobot />
                <FleetAssistantQuote
                    onClose={handleClose}
                    isChecked={isChecked}
                    onCheckboxChange={handleCheckboxChange}
                />
            </div>
        </div>
    );
};

export default connect()(FleetAssistantPhaseOne);
