import React from 'react';

import robotImage from './robot.svg';

const FleetAssistantRobot = () => {
    const keyframesStyle = `
        @keyframes flyIn {
            0% { transform: translateY(30px); opacity: 0; }
            100% { transform: translateY(0); opacity: 1; }
        }
        @keyframes float {
            0% { transform: translateY(0); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0); }
        }
    `;

    const robotContainerStyle: React.CSSProperties = {
        marginTop: '28rem',
        marginBottom: '1rem',
        animation: 'flyIn 1s ease-out forwards, float 3s infinite ease-in-out',
    };

    const robotImageStyle: React.CSSProperties = {
        width: '90px',
        height: 'auto',
        zIndex: 1020,
    };

    return (
        <>
            <style>{keyframesStyle}</style>

            <div className="assistant-robot-moving" style={robotContainerStyle}>
                <img src={robotImage} alt="Animated Robot" style={robotImageStyle} />
            </div>
        </>
    );
};

export default FleetAssistantRobot;
