import { array, literal, string, type, TypeOf } from 'io-ts';

import { VehicleGroup } from '../models/types';
import { decodeJson } from './decoder';
import { getGroups } from './mfhConverter';

export const TagApiResponseItem = type({
    id: string,
    name: string,
    type: literal('user'),
});

const TagApiResponse = type({
    items: array(TagApiResponseItem),
});

export type TagApiResponseType = TypeOf<typeof TagApiResponse>;

export function decodeGroups(rawTags: TagApiResponseType): VehicleGroup[] {
    const tags = decodeJson(TagApiResponse, rawTags);
    return getGroups(tags);
}
