import { TypeOf } from 'io-ts';

import { AuthToken } from '../types';
import { fetchGetWithJSONResponse, fetchGetWithJSONResponseCyclicWithPagination } from './api';
import {
    Asset,
    AssetApiResponseType,
    Association,
    AssociationApiResponseType,
    decodeVehicle,
    PlatformInfoVehicleApiResponseType,
} from './decoder/vehicleDecoder';
import mergeResponses from './helpers/mergeResponses';

const fetchAssetsWithPagination = async (baseUrl: string, api: string, authToken: AuthToken) => {
    const responses = await fetchGetWithJSONResponseCyclicWithPagination<AssetApiResponseType>(baseUrl, api, authToken);
    return mergeResponses<TypeOf<typeof Asset>, AssetApiResponseType>(responses);
};

const fetchDeviceWithPagination = async (baseUrl: string, api: string, authToken: AuthToken) => {
    const responses = await fetchGetWithJSONResponseCyclicWithPagination<AssociationApiResponseType>(
        baseUrl,
        api,
        authToken
    );
    return mergeResponses<TypeOf<typeof Association>, AssociationApiResponseType>(responses);
};

export async function fetchVehicles(baseUrlAssets: string, baseUrlPlatformInfo: string, authToken: string) {
    const assetRequest = fetchAssetsWithPagination(baseUrlAssets, `assets?embed=(tags)`, authToken);
    const associationsRequest = fetchDeviceWithPagination(baseUrlAssets, `associations?embed=(device)`, authToken);

    const [rawAssets, rawAssociations]: [AssetApiResponseType, AssociationApiResponseType] = await Promise.all([
        assetRequest,
        associationsRequest,
    ]);

    let rawPlatformInfoVehicles: PlatformInfoVehicleApiResponseType;
    try {
        rawPlatformInfoVehicles = await fetchGetWithJSONResponse(baseUrlPlatformInfo, `permitted-assets`, authToken);
    } catch {
        rawPlatformInfoVehicles = { items: [] };
    }

    return decodeVehicle(rawAssets, rawAssociations, rawPlatformInfoVehicles);
}
