import { array, intersection, literal, nullType, partial, string, type, TypeOf, union } from 'io-ts';

import { Vehicle, VehicleFuelType, VehicleSegment, VehicleStatus, VehicleType } from '../models/types';
import { decodeJson } from './decoder';
import { getVehicles } from './mfhConverter';

export const Asset = type({
    account_id: string,
    brand: union([string, nullType]),
    id: string,
    identification: union([string, nullType]),
    identification_type: union([literal('vin'), nullType]),
    name: string,
    status: union([literal(VehicleStatus.ACTIVE), literal(VehicleStatus.ARCHIVED)]),
    type: union([
        literal(VehicleType.TRAILER.toLowerCase()),
        literal(VehicleType.BUS.toLowerCase()),
        literal(VehicleType.TRUCK.toLowerCase()),
        literal(VehicleType.VAN.toLowerCase()),
    ]),
    _embedded: type({
        tags: type({
            items: array(
                type({
                    id: string,
                })
            ),
        }),
    }),
});

const AssetApiResponse = type({
    items: array(Asset),
});

export const Association = type({
    asset_id: string,
    _embedded: type({
        device: type({
            type: string, // we soften this here, in order to not explode when new types get added
        }),
    }),
});

export const AssociationApiResponse = type({
    items: array(
        intersection([
            partial({
                asset_id: string,
            }),
            Association,
        ])
    ),
});

const PlatformInfoVehicleApiResponse = type({
    items: array(
        type({
            assetId: string,
            fuelType: union([
                literal(VehicleFuelType.DIESEL),
                literal(VehicleFuelType.ELECTRIC),
                literal(VehicleFuelType.GAS),
                literal(VehicleFuelType.HYDROGEN),
                literal(VehicleFuelType.UNKNOWN),
            ]),
            identification: union([string, nullType]),
            name: string,
            product: string,
            segment: union([
                literal(VehicleSegment.CITY),
                literal(VehicleSegment.COACH),
                literal(VehicleSegment.INTER_CITY),
                literal(VehicleSegment.UNKNOWN),
            ]),
            type: union([
                literal(VehicleType.TRAILER),
                literal(VehicleType.BUS),
                literal(VehicleType.TRUCK),
                literal(VehicleType.VAN),
            ]),
        })
    ),
});

export type AssociationApiResponseType = TypeOf<typeof AssociationApiResponse>;
export type AssetApiResponseType = TypeOf<typeof AssetApiResponse>;
export type PlatformInfoVehicleApiResponseType = TypeOf<typeof PlatformInfoVehicleApiResponse>;
export type AssetType = TypeOf<typeof Asset>;

export function decodeVehicle(
    rawAssets: AssetApiResponseType,
    rawAssociations: AssociationApiResponseType,
    rawPlatformInfoVehicles: PlatformInfoVehicleApiResponseType
): Vehicle[] {
    const assets = decodeJson(AssetApiResponse, rawAssets);
    const associations = decodeJson(AssociationApiResponse, rawAssociations);
    const additionalVehiclesInfos = decodeJson(PlatformInfoVehicleApiResponse, rawPlatformInfoVehicles);
    return getVehicles(assets, associations, additionalVehiclesInfos);
}
