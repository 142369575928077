import FleetAssistantOnBoarding from '@features/fleetAssistant/FleetAssistantOnBoarding/FleetAssistantOnBoarding';
import { getFleetFlagFromLocalStorage, saveFleetFlagToLocalStorage } from '@features/ui/utils';
import { useState } from 'react';
import { connect } from 'react-redux';

export const onboardingFlagKey = 'hasSeenFleetAssistantOnboarding';
const hasSeenOnboarding = !getFleetFlagFromLocalStorage(onboardingFlagKey);

const FleetAssistantComingSoon = () => {
    const [showOnboarding, toggleOnboarding] = useState<boolean>(hasSeenOnboarding);

    const handleOnBoardingClick = () => {
        toggleOnboarding(!showOnboarding);
        saveFleetFlagToLocalStorage(onboardingFlagKey);
    };

    const onOnboardingText = {
        header: 'fleetAssistant.onboarding.header',
        body: 'fleetAssistant.onboarding.body',
        footer: 'fleetAssistant.onboarding.footer',
    };

    return (
        <FleetAssistantOnBoarding
            showOnboarding={showOnboarding}
            showIconOnboarding={true}
            onOnboardingText={onOnboardingText}
            handleOnBoardingClick={handleOnBoardingClick}
        />
    );
};

export default connect()(FleetAssistantComingSoon);
