import { getSignal } from '@api/index';
import { isKoreanTenant } from '@common/login/selectors';
import { getFeatureToggles } from '@common/permissions/selectors';
import ErrorBoundary from '@components/ErrorBoundry';
import TabbableContent from '@components/TabbableContent';
import { AssistanceSystemsByDriver } from '@features/assistanceSystems/AssistanceSystems';
import Battery from '@features/battery/Battery';
import { DriveProgramsByDriver } from '@features/drivePrograms/DrivePrograms';
import EnvironmentConditions from '@features/environmentConditions/EnvironmentConditions';
import { ForesightedDrivingByDriver } from '@features/foresightedDriving/ForesightedDriving';
import Total from '@features/foresightedDriving/Total';
import OperationalRange from '@features/opcon/OperationalRange';
import OperationConditionRating from '@features/opcon/OperationConditionRating';
import TrafficJams from '@features/opcon/TrafficJams';
import {
    CalculationParams,
    enablePerform3,
    includeCruiseControlInRating,
    savedCalculationParams,
} from '@features/settings/reducer';
import VehicleSpecification from '@features/vehicleSpecification/VehicleSpecification';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { LoginStatePart, PermissionsStatePart, SettingStatePart } from 'src/setup/types';

import { DateRange, HydratedEntity, Vehicle, VehicleType } from '../../types';

export const DriverSidebarTabs: React.FC<{
    dateRange: DateRange;
    entity: any;
    allowedVehicles: string[];
    isKoreanTenant: boolean;
    includeCruiseControlInRating: boolean;
    calculationParams: CalculationParams;
    shouldShowOvertakeInCruise?: boolean;
    shouldUseV2Ratings?: boolean;
    shouldShowHarshKPIs?: boolean;
    shouldShowExcessiveIdlingKPIs?: boolean;
    isTruEEnabled?: boolean;
    containsElectricVehicle?: boolean;
    shouldUseV3Ratings?: boolean;
    shouldShowTrueDrivingRatings?: boolean;
}> = ({
    dateRange,
    entity,
    allowedVehicles,
    isKoreanTenant,
    includeCruiseControlInRating,
    calculationParams,
    shouldShowOvertakeInCruise = false,
    shouldUseV2Ratings = true,
    shouldShowHarshKPIs = false,
    shouldShowExcessiveIdlingKPIs = false,
    isTruEEnabled = false,
    containsElectricVehicle = false,
    shouldUseV3Ratings = false,
    shouldShowTrueDrivingRatings = false,
}) => {
    const vehicleIds = getSignal(entity, 'vehicleIds', []);
    const driverIds = getSignal(entity, 'driverIds', []);
    const mileage = getSignal(entity, 'mileage', 0);
    const isTopLevelWithMoreThan1Vehicle = vehicleIds.length > 1;
    const vehicleIdsWithLevelAwareness = isTopLevelWithMoreThan1Vehicle ? [] : vehicleIds;
    const showTemperatureHistogram = shouldUseV2Ratings;
    const props = {
        dateRange,
        vehicleIds: vehicleIdsWithLevelAwareness,
        driverIds,
        mileageFromPerformance: mileage,
        canSeeRoute: false,
        isRouteEnabled: false,
    };

    const vehicles = getSignal<Vehicle[]>(entity, 'vehicles', []);
    const isOnlyOneVehicleSelected = vehicles.length === 1;
    const hideDrivingPrograms =
        isOnlyOneVehicleSelected && (vehicles[0].type === VehicleType.VAN || vehicles[0].type === VehicleType.BUS);
    const showBatteryTab = isTruEEnabled && containsElectricVehicle;

    const tabs = [
        ...(showBatteryTab
            ? [
                  {
                      testTag: 'battery',
                      title: <FormattedMessage id="battery" />,
                      content: <Battery {...props} vehicleIds={vehicleIds} driverIds={driverIds} />,
                  },
              ]
            : []),
        ...(shouldUseV2Ratings
            ? [
                  ...(!hideDrivingPrograms
                      ? [
                            {
                                testTag: 'drivingPrograms',
                                title: <FormattedMessage id="drivingPrograms" />,
                                content: (
                                    <DriveProgramsByDriver
                                        start={dateRange.start}
                                        end={dateRange.end}
                                        vehicleIds={vehicleIds}
                                        driverIds={driverIds}
                                        allowedVehicles={allowedVehicles}
                                    />
                                ),
                            },
                        ]
                      : []),
                  {
                      testTag: 'assistanceSystems',
                      title: <FormattedMessage id="assistanceSystems" />,
                      content: (
                          <AssistanceSystemsByDriver
                              start={dateRange.start}
                              end={dateRange.end}
                              vehicleIds={vehicleIds}
                              driverIds={driverIds}
                              allowedVehicles={allowedVehicles}
                          />
                      ),
                  },
              ]
            : []),
        {
            testTag: 'driving',
            title: <FormattedMessage id="sidepanel.foresightedDriving" />,
            content: (
                <ErrorBoundary>
                    <ForesightedDrivingByDriver
                        start={dateRange.start}
                        end={dateRange.end}
                        vehicleIds={vehicleIds}
                        driverIds={driverIds}
                        allowedVehicles={allowedVehicles}
                        includeCruiseControlInRating={includeCruiseControlInRating}
                        calculationParams={calculationParams}
                        shouldShowOvertakeInCruise={shouldShowOvertakeInCruise}
                        shouldUseV2Ratings={shouldUseV2Ratings}
                        shouldShowHarshKPIs={shouldShowHarshKPIs}
                        shouldShowExcessiveIdlingKPIs={shouldShowExcessiveIdlingKPIs}
                        isTruEEnabled={isTruEEnabled}
                        shouldUseV3Ratings={shouldUseV3Ratings}
                        shouldShowTrueDrivingRatings={shouldShowTrueDrivingRatings}
                    >
                        {({ entity }: { entity: HydratedEntity }) => (
                            <div className="display-flex equal-height">
                                {!isKoreanTenant && (
                                    <div style={{ flex: 1 }} className="margin-right-15">
                                        <OperationConditionRating {...props} />
                                    </div>
                                )}
                                <div style={{ flex: 1 }}>
                                    <Total entity={entity} shouldUseV3Ratings={shouldUseV3Ratings} />
                                </div>
                            </div>
                        )}
                    </ForesightedDrivingByDriver>
                </ErrorBoundary>
            ),
        },
        {
            testTag: 'vehspec',
            title: <FormattedMessage id="vehicleSpecification" />,
            content: <VehicleSpecification {...props} vehicleIds={vehicleIds} />,
        },
        ...(!isKoreanTenant
            ? [
                  {
                      testTag: 'map',
                      title: <FormattedMessage id="map" />,
                      content: <OperationalRange {...props} />,
                  },
              ]
            : []),
        ...(!isKoreanTenant
            ? [
                  {
                      testTag: 'traffic',
                      title: <FormattedMessage id="traffic" />,
                      content: <TrafficJams {...props} />,
                  },
              ]
            : []),
        ...(!isKoreanTenant
            ? [
                  {
                      testTag: 'environmentConditions',
                      title: <FormattedMessage id="environmentConditions" />,
                      content: (
                          <EnvironmentConditions
                              {...props}
                              allowedVehicles={allowedVehicles}
                              showTemperatureHistogram={showTemperatureHistogram}
                          />
                      ),
                      disabled: isKoreanTenant,
                  },
              ]
            : []),
    ];

    return (
        <TabbableContent tabs={tabs} needsBottomSpacing={!includeCruiseControlInRating} location="drivingAnalysis" />
    );
};

export function mapStateToProps(state: PermissionsStatePart & SettingStatePart & LoginStatePart) {
    return {
        isKoreanTenant: isKoreanTenant(state),
        includeCruiseControlInRating: includeCruiseControlInRating(state),
        calculationParams: savedCalculationParams(state),
        shouldShowHarshKPIs: getFeatureToggles(state).showHarshKPIs as boolean,
        shouldShowExcessiveIdlingKPIs: getFeatureToggles(state).showExcessiveKPIs as boolean,
        shouldUseV2Ratings: enablePerform3(state),
        isTruEEnabled: getFeatureToggles(state).truE_EEF as boolean,
        shouldUseV3Ratings: getFeatureToggles(state).newOvertakeModel as boolean,
        shouldShowTrueDrivingRatings: getFeatureToggles(state).trueDrivingRatings as boolean,
    };
}

export default connect(mapStateToProps)(DriverSidebarTabs);
