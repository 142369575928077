import { getColumn, getColumns } from '@utils/columns';
import { FormattedMessage } from 'react-intl';

export const coasting = {
    id: 'coasting',
    title: getColumn('coastingRating'),
    entries: [
        {
            title: <FormattedMessage id="activeCoasting" />,
            values: getColumns(['activeCoastingDistance', 'activeCoastingTime']),
        },
        {
            title: <FormattedMessage id="passiveCoasting" />,
            values: getColumns(['coastingCcDistance', 'coastingCcTime']),
        },
    ],
};

export const coastingPerform3 = {
    ...coasting,
    entries: [
        {
            title: <FormattedMessage id="activeCoasting" />,
            values: getColumns(['activeCoastingDistance', 'activeCoastingTime']),
        },
        {
            title: <FormattedMessage id="passiveCoasting" />,
            values: getColumns(['passiveCoastingDistance', 'passiveCoastingTime']),
        },
    ],
};

export const acceleration = {
    id: 'accelerationPedal',
    title: getColumn('accelerationPedalRating'),
    entries: [
        {
            title: <FormattedMessage id="kickdown" />,
            values: getColumns(['kickdownDistance', 'kickdownTime']),
        },
        {
            title: <FormattedMessage id="cruiseControl" />,
            values: getColumns(['accelerationPedalDistanceWithCc', 'accelerationPedalTimeWithCc']),
        },
    ],
};

export const accelerationBoost = {
    ...acceleration,
    title: getColumn('accelerationPedalRatingV2'),
    entries: [
        {
            title: <FormattedMessage id="boost" />,
            values: getColumns(['kickdownDistance', 'kickdownTime']),
        },
        {
            title: <FormattedMessage id="cruiseControl" />,
            values: getColumns(['accelerationPedalDistanceWithCc', 'accelerationPedalTimeWithCc']),
        },
    ],
};

export const electricRecuperationRating = {
    id: 'electricRecuperationRating',
    title: getColumn('electricRecuperationRating'),
    entries: [
        {
            title: <FormattedMessage id="electricRecuperation" />,
            values: getColumns(['electricRecuperation']),
        },
    ],
};

export const accelerationPerform3 = {
    ...acceleration,
    title: getColumn('accelerationPedalRatingV2'),
};

export const accelerationPerform3v3 = {
    ...acceleration,
    title: getColumn('accelerationPedalRatingV3'),
};

export const accelerationPerform3WithOvertake = {
    ...acceleration,
    title: getColumn('accelerationPedalRatingV2'),
    entries: [
        {
            title: <FormattedMessage id="kickdown" />,
            values: getColumns(['kickdownDistance', 'kickdownTime']),
        },
    ],
};

export const braking = {
    id: 'braking',
    title: getColumn('brakingRating'),
    entries: [
        {
            title: <FormattedMessage id="serviceBrake" />,
            values: getColumns(['serviceBrakeDistance', 'serviceBrakeTime']),
        },
        {
            title: <FormattedMessage id="motorBrake" />,
            values: getColumns(['motorBrakeDistance', 'motorBrakeTime']),
        },
        {
            title: <FormattedMessage id="retarder" />,
            values: getColumns(['retarderDistance', 'retarderTime']),
        },
    ],
};

export const harshAcceleration = {
    id: 'harshAcceleration',
    title: getColumn('harshAccelerationRating'),
    entries: [
        {
            title: <FormattedMessage id="medium" />,
            values: getColumns(['harshAccelerationMediumCount', 'harshAccelerationMediumTime']),
        },
        {
            title: <FormattedMessage id="hard" />,
            values: getColumns(['harshAccelerationHardCount', 'harshAccelerationHardTime']),
        },
    ],
};

export const harshBraking = {
    id: 'harshBraking',
    title: getColumn('harshBrakingRating'),
    entries: [
        {
            title: <FormattedMessage id="medium" />,
            values: getColumns(['harshBrakingMediumCount', 'harshBrakingMediumTime']),
        },
        {
            title: <FormattedMessage id="hard" />,
            values: getColumns(['harshBrakingHardCount', 'harshBrakingHardTime']),
        },
    ],
};

export const cruise = {
    id: 'cruiseControl',
    title: getColumn('cruiseControlRating'),
    entries: [
        {
            title: <FormattedMessage id="withCruiseControl" />,
            values: getColumns(['cruiseControlDistanceInPotential', 'cruiseControlTimeInPotential']),
        },
        {
            title: <FormattedMessage id="withoutCruiseControl" />,
            values: getColumns(['noCruiseControlDistanceInPotential', 'noCruiseControlTimeInPotential']),
        },
        {
            title: <FormattedMessage id="cruiseControlPotentialDistance" />,
            values: getColumns(['cruiseControlPotentialDistance']),
        },
    ],
};

export const electricCruise = {
    ...cruise,
    title: getColumn('cruiseControlRatingV2'),
    entries: [...cruise.entries],
};

export const fuelCruise = {
    ...cruise,
    title: getColumn('cruiseControlRatingV2'),
    entries: [
        ...cruise.entries,
        {
            title: <FormattedMessage id="cruiseControlConsumption" />,
            values: getColumns(['cruiseControlConsumptionInPotential']),
        },
        {
            title: <FormattedMessage id="noCruiseControlConsumption" />,
            values: getColumns(['noCruiseControlConsumptionInPotential']),
        },
    ],
};

export const fuelCruiseV1 = {
    ...cruise,
    title: getColumn('cruiseControlRating'),
    entries: [
        ...cruise.entries,
        {
            title: <FormattedMessage id="cruiseControlConsumption" />,
            values: getColumns(['cruiseControlConsumptionInPotential']),
        },
        {
            title: <FormattedMessage id="noCruiseControlConsumption" />,
            values: getColumns(['noCruiseControlConsumptionInPotential']),
        },
    ],
};

export const fuelCruiseBevV1 = {
    ...cruise,
    title: getColumn('cruiseControlRating'),
};

export const cruisePerform3 = {
    ...fuelCruise,
    title: getColumn('cruiseControlRatingV2'),
};

export const cruisePerform3v3 = {
    ...fuelCruise,
    title: getColumn('cruiseControlRatingV3'),
};

export const cruiseBevPerform3 = {
    ...cruise,
    title: getColumn('cruiseControlRatingV2'),
};

export const cruiseBevPerform3v3 = {
    ...cruise,
    title: getColumn('cruiseControlRatingV3'),
};

export const electricCruisePerform3 = {
    ...cruise,
    ...electricCruise,
    title: getColumn('cruiseControlRatingV2'),
};

export const electricCruisePerform3v3 = {
    ...cruise,
    ...electricCruise,
    title: getColumn('cruiseControlRatingV3'),
};

export const cruisePerform3WithOvertake = {
    ...fuelCruise,
    title: getColumn('cruiseControlRatingV2'),
    entries: [
        ...fuelCruise.entries,
        {
            title: <FormattedMessage id="overtakeMode" defaultMessage="Overtake mode (default msg)" />, // overtake mode
            values: getColumns(['accelerationPedalDistanceWithCc']),
        },
    ],
};

export const cruiseBevPerform3WithOvertake = {
    ...cruise,
    title: getColumn('cruiseControlRatingV2'),
    entries: [
        ...cruise.entries,
        {
            title: <FormattedMessage id="overtakeMode" defaultMessage="Overtake mode (default msg)" />, // overtake mode
            values: getColumns(['accelerationPedalDistanceWithCc']),
        },
    ],
};

export const electricCruisePerform3WithOvertake = {
    ...electricCruise,
    title: getColumn('cruiseControlRatingV2'),
    entries: [
        ...electricCruise.entries,
        {
            title: <FormattedMessage id="overtakeMode" defaultMessage="Overtake mode (default msg)" />, // overtake mode
            values: getColumns(['accelerationPedalDistanceWithCc']),
        },
    ],
};

export const overspeed = {
    id: 'overspeed',
    title: getColumn('overspeedRating'),
    entries: [
        {
            title: <FormattedMessage id="overspeed" />,
            values: getColumns(['overspeedDistance']),
        },
    ],
};

export const excessiveIdling = {
    id: 'excessiveIdling',
    title: getColumn('excessiveIdlingRating'),
    entries: [
        {
            title: <FormattedMessage id="acceptableIdlingTime" />,
            values: getColumns(['acceptableIdlingTime']),
        },
        {
            title: <FormattedMessage id="excessiveIdlingTime" />,
            values: getColumns(['excessiveIdlingTime']),
        },
        {
            title: <FormattedMessage id="excessiveIdlingEvents" />,
            values: getColumns(['excessiveIdlingCount']),
        },
    ],
};
