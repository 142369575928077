import { Text, View } from '@react-pdf/renderer';
import { getPluralization } from '@utils/getPluralization';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Driver, DriverType, Vehicle } from '../../../types';

export const getNumberOfDrivers = (drivers: Driver[]) => {
    const createUniqueKey = (item: Driver) => {
        return 'driverId' in item && item.driverId ? `id:${item.driverId}` : `name:${item.type}`;
    };

    const uniqueKeys = new Set(drivers.map(createUniqueKey));
    return uniqueKeys.size;
};

export default function VehicleSummary({ vehicles, drivers }: { vehicles: Vehicle[]; drivers: Driver[] }) {
    const pluralizedDriver = getPluralization('driver', 'drivers', getNumberOfDrivers(drivers));
    const pluralizedVehicle = getPluralization('vehicle', 'vehicles', vehicles.length);

    const keepOneDriverType = (objects: Driver[]) => {
        let driverKept = false;

        return objects.filter(obj => {
            if (obj.type !== DriverType.ANONYMOUS || DriverType.NO_DRIVER_CARD) {
                return true;
            }

            if (!driverKept) {
                driverKept = true;
                return true;
            }

            return false;
        });
    };

    const filteredDrivers = keepOneDriverType(drivers);

    return (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <FormattedMessage id="print.with" />
            <Text> </Text>
            {vehicles.length ? (
                <React.Fragment>
                    <Text style={{ fontFamily: 'Bold' }}>
                        <FormattedMessage
                            id="vehicleCount"
                            values={{
                                vehicleCount: vehicles.length,
                                pluralizedVehicle,
                            }}
                        />
                    </Text>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <FormattedMessage id="fleetOf" />
                    <Text style={{ fontFamily: 'Bold' }}>
                        <FormattedMessage
                            id="vehicleCount"
                            values={{
                                vehicleCount: vehicles.length,
                                pluralizedVehicle,
                            }}
                        />
                    </Text>
                </React.Fragment>
            )}
            <Text> </Text>
            <FormattedMessage id="print.and.team" />
            <Text> </Text>
            {filteredDrivers.length ? (
                <React.Fragment>
                    <Text style={{ fontFamily: 'Bold' }}>
                        <FormattedMessage
                            id="driverCount"
                            values={{
                                driverCount: filteredDrivers.length,
                                pluralizedDriver,
                            }}
                        />
                    </Text>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <FormattedMessage id="teamOf" />
                    <Text style={{ fontFamily: 'Bold' }}>
                        <FormattedMessage
                            id="driverCount"
                            values={{
                                driverCount: drivers.length,
                                pluralizedDriver,
                            }}
                        />
                    </Text>
                </React.Fragment>
            )}
        </View>
    );
}
