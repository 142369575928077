import { get } from 'lodash';
import getOr from 'lodash/fp/getOr';
import { LoginStatePart } from 'src/setup/types';

export const getUserProfile = getOr(null, 'login.userProfile');
// For some reason this creates problems with typescript when using getAccount function
// export const getAccount = getOr(null, 'login.userProfile.account');
export const getAccount = (state: LoginStatePart) => get(state, 'login.userProfile.account', undefined);

export const isKoreanTenant = (state: LoginStatePart) => {
    return get(state, 'login.userProfile.tenant', '').includes('rio-southkorea');
};

export const isLATAMTenant = (state: LoginStatePart) => {
    return get(state, 'login.userProfile.tenant', '').includes('rio-brazil');
};

export const hasUserSessionEverExpired = (state: LoginStatePart) => get(state, 'login.hasUserSessionEverExpired');

export const isUserSessionExpired = (state: LoginStatePart) => get(state, 'login.userSessionExpired');

export const isWhitelistedPerform3Account = (state: LoginStatePart) => {
    const whitelistedAccounts = [
        '237c58e3-60b5-4442-a9ec-8641ea39325b', // our account
        '4c8e1f07-8510-4bbf-79ca-9d706675b9da', // our local account
        '2a4b5e75-b60b-4412-95a2-e4c6f73e17fe', // Hillert
        // '23639feb-ca5e-4b9a-9e16-5e9cf92f07e2', // Bero (Behrens-Gruppe)
        // 'b2a555e4-5d51-4049-85df-edeec2b31781', // Wienkämper (mixed-fleet test account)
        '3ff8cda3-6b22-4de5-8595-aac6974e7ff4', // MAN Truck & Bus AG ProfiDrive
        // '3d8eac7c-d6f4-422e-90bc-a6fa7778e9f0', // MDS France
        // '284b3c1f-72c1-4669-a51b-c7ca9cc3c967', // Demofleet MAN Sales
        '157f142d-55fc-428d-b4fc-20b7fab9ccfd', // Greß Speditions GmbH
        // '36ae1852-a244-4a5f-b4b3-9a46ef628a78', // KS Logistik und Services GmbH
        '7105309d-a97f-4fd6-9138-923c262eea8f', // IW-Transporte
        '1cea77ad-5ce2-4452-b0af-6cae14ab5f5f', // MAN Truck & Bus Vertrieb Österreich GesmbH
        '8ef04c71-8105-4bfb-a97c-a70db9d66975', // VIT fleet
        'f9a3e541-6ccf-4efb-8079-1b57b263969d', // Demo Fleet
        '6ce5c381-524c-44de-9c05-a73390b1f999',
        '00e2b138-77ac-4187-b424-b5da1de467f2', // Fehrtrans
        '7b90da1e-82d8-4b49-96ad-8afce71b6a57', // Remondis
        '77983250-0e1f-45a1-9faa-3c3a0f32df93',
        '62d33f7b-ffae-420b-9487-c96e38f4df9d', // Weinmann
        '69416bb4-7270-4c2a-b374-1fd4cd799caf', // Bellersheim
        '1a111f79-e986-4e32-881d-f7fe7bda5c88', // Remondis GmbH & Co. KG
        'e8182c99-696a-4725-ac57-9cfec83b729b', // Remondis GmbH
        'dde0dd8a-7fb9-47cd-b0d4-e88a669a280b', // Remondis GmbH & Co. KG Melsdorf
        '3b493498-e429-4c3a-a1a0-7ef259d922c1', // Remondis GmbH ZNL Schleswig
        'e9b0357d-5b9c-40ef-b370-6fc3acf98c1b', // Remondis GmbH & Co. KG Neumünster
        '4ca4e0ac-2092-4948-b4bc-a3655ac296ef', // AutoTRANS Schmidt GmbH
        'f8a883eb-5509-4cbd-aa19-cf9118216b3c', // Gelhard Logistik Service GmbH
        '4924568e-23f3-40f9-9730-ff88efc41172', // LATAM test account
        '93484342-d1e8-46c3-ac6b-db8983ea232c', // Schenker Deutschland AG
        '53501370-d0f6-4882-8156-fa40415ca79e', // Milchtransporte Niederrhein GmbH
        'a350fdb9-9af5-4123-a0dc-e6b2c61c8ae7', // MAN truck & bus B.V.
        '009c67fb-5ab3-4165-b612-228ffe9b1275', // Man Nederland Dealer Duiven
        '3ddf670a-c825-4763-8075-5d316864331c', // Vortex Forwarding B.V.
        'f65b6a1d-0883-4a78-a23f-21d8e17fa7bb', // B.T.S. Bedrijfswagens & Trailer Service B.V.
        '5019db16-f0c7-4f1d-ab80-a597ece4c2d6', // Volkswagen Pon Financial Services B.V.
    ];

    return whitelistedAccounts.includes(get(state, 'login.userProfile.account', ''));
};

export const isWhitelistedCalculationParamsPreset = (state: LoginStatePart) => {
    const whitelistedAccounts = [
        '237c58e3-60b5-4442-a9ec-8641ea39325b', // our account
        '4c8e1f07-8510-4bbf-79ca-9d706675b9da', // our local account
        'b2a555e4-5d51-4049-85df-edeec2b31781', // Wienkämper (mixed-fleet test account)
        '284b3c1f-72c1-4669-a51b-c7ca9cc3c967', // Demofleet MAN Sales
    ];

    return whitelistedAccounts.includes(get(state, 'login.userProfile.account', ''));
};

export const isWhitelistedOpcon3BetaAccount = (state: LoginStatePart) => {
    const whitelistedAccounts = [
        '237c58e3-60b5-4442-a9ec-8641ea39325b', // our account
        '4c8e1f07-8510-4bbf-79ca-9d706675b9da', // local account
        '16d5b0c1-a52d-4bdf-b558-78ecef42a565', // Hagero SRL (Rumänien)
    ];
    return whitelistedAccounts.includes(get(state, 'login.userProfile.account', ''));
};

export const isAllowedToUseFleetAssistant = (state: LoginStatePart) => {
    const allowedFleetAssistantCountries = ['AT'];
    const country = get(state, 'login.countryCode');
    return country != null && allowedFleetAssistantCountries.includes(country);
};
