import FleetAssistantDialog from '@features/fleetAssistant/FleetAssistantDialog/FleetAssistantDialog';
import FleetAssistantWelcome from '@features/fleetAssistant/FleetAssistantOnBoarding/FleetAssistantWelcome';
import { toggleFleetDialog } from '@features/fleetAssistant/utils/reducer';
import { shouldShowFleetDialog } from '@features/fleetAssistant/utils/selectors';
import { Dispatch } from '@reduxjs/toolkit';
import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import React from 'react';
import { connect } from 'react-redux';

import { State } from '../../../setup/types';

interface FleetAssistantProps {
    toggleFleetDialog: () => void;
    isFleetDialogVisible: boolean;
    fleetSrc: string;
}

const FleetAssistant = ({ toggleFleetDialog, isFleetDialogVisible, fleetSrc }: FleetAssistantProps) => {
    return (
        <ActionBarItem data-test="fleet-assistant-container">
            <ActionBarItem.Icon onClick={toggleFleetDialog}>
                <div role="button" style={{ display: 'inline-block' }}>
                    <FleetAssistantWelcome />
                </div>
            </ActionBarItem.Icon>
            <React.Fragment>
                <FleetAssistantDialog
                    data-test="fleet-assistant-dialog"
                    show={isFleetDialogVisible}
                    toggleFleetDialog={toggleFleetDialog}
                    fleetSrc={fleetSrc}
                />
            </React.Fragment>
        </ActionBarItem>
    );
};

const mapStateToProps = (state: State) => ({
    isFleetDialogVisible: shouldShowFleetDialog(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleFleetDialog: () => dispatch(toggleFleetDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FleetAssistant);
