import {
    formatOneDigitNumber,
    formatTwoOrThreeDecimals,
    getWeightedAverageBy,
    plainAverage,
    weightAverageByMileage,
    withUnit,
} from '@utils/formatters';
import { ReactNode } from 'react';
import { compose } from 'redux';

import {
    ELECTRIC_EFFICIENCY,
    FUEL_EFFICIENCY,
    KILOMETERS_PER_KILOWATTS_HOUR,
    KILOMETERS_PER_LITERS,
    KILOWATTS_HOUR,
    KILOWATTS_HOUR_PER_HOUR,
    KILOWATTS_HOUR_PER_KM,
    LITERS,
    LITERS_PER_100KM,
    LITERS_PER_HOUR,
} from '../constants/units';
import createColumn from './createColumn';

export const consumptionPerTimeSignal = {
    unit: LITERS_PER_HOUR,
    displayedUnit: LITERS_PER_HOUR,
    format: compose<ReactNode>(withUnit(LITERS_PER_HOUR), formatOneDigitNumber),
    average: (values: number[]) => getWeightedAverageBy(values),
};

export const electricConsumptionPerTimeSignal = {
    unit: KILOWATTS_HOUR_PER_HOUR,
    displayedUnit: KILOWATTS_HOUR_PER_HOUR,
    format: compose<ReactNode>(withUnit(KILOWATTS_HOUR_PER_HOUR), formatOneDigitNumber),
    average: (values: number[]) => getWeightedAverageBy(values),
};

export const distancePerConsumptionSignal = {
    unit: KILOMETERS_PER_LITERS,
    displayedUnit: KILOMETERS_PER_LITERS,
    format: compose<ReactNode>(withUnit(KILOMETERS_PER_LITERS), formatOneDigitNumber),
    average: weightAverageByMileage,
};

export const distancePerElectricConsumptionSignal = {
    unit: KILOMETERS_PER_KILOWATTS_HOUR,
    displayedUnit: KILOMETERS_PER_KILOWATTS_HOUR,
    format: compose<ReactNode>(withUnit(KILOMETERS_PER_KILOWATTS_HOUR), formatOneDigitNumber),
    average: weightAverageByMileage,
};

export const fuelEfficiencySignal = {
    unit: FUEL_EFFICIENCY,
    displayedUnit: FUEL_EFFICIENCY,
    format: compose<ReactNode>(withUnit(FUEL_EFFICIENCY), formatOneDigitNumber),
    average: plainAverage,
};

export const consumptionSignal = {
    unit: LITERS,
    displayedUnit: LITERS,
    rawFormat: formatOneDigitNumber,
    format: compose<ReactNode>(withUnit(LITERS), formatOneDigitNumber),
    average: plainAverage,
};

export const electricConsumptionSignal = {
    unit: KILOWATTS_HOUR,
    displayedUnit: KILOWATTS_HOUR,
    rawFormat: formatOneDigitNumber,
    format: compose<ReactNode>(withUnit(KILOWATTS_HOUR), formatOneDigitNumber),
    average: plainAverage,
};

export const consumptionPerDistanceSignal = {
    unit: LITERS_PER_100KM,
    displayedUnit: LITERS_PER_100KM,
    format: compose<ReactNode>(withUnit(LITERS_PER_100KM), formatOneDigitNumber),
    average: weightAverageByMileage,
};

export const electricConsumptionPerDistanceSignal = {
    unit: KILOWATTS_HOUR_PER_KM,
    displayedUnit: KILOWATTS_HOUR_PER_KM,
    format: compose<ReactNode>(withUnit(KILOWATTS_HOUR_PER_KM), formatTwoOrThreeDecimals),
    average: weightAverageByMileage,
};

export const fuelConsumption = createColumn({
    key: 'fuelConsumption',
    dataField: 'fuelConsumption',
    labelId: 'fuelConsumption',
    formatter: consumptionSignal.format,
    unit: consumptionSignal.unit,
});

export const electricOperatingConsumption = createColumn({
    key: 'electricOperatingConsumption',
    dataField: 'electricOperatingConsumption',
    labelId: 'fuelConsumption',
    formatter: electricConsumptionSignal.format,
    unit: electricConsumptionSignal.unit,
});

export const cruiseControlConsumptionInPotential = createColumn({
    key: 'cruiseControlConsumptionInPotential',
    dataField: 'cruiseControlConsumptionInPotential',
    labelId: 'cruiseControlConsumptionInPotential',
    formatter: consumptionPerDistanceSignal.format,
    unit: consumptionPerDistanceSignal.unit,
});

export const electricCruiseControlConsumptionInPotential = createColumn({
    key: 'electricCruiseControlConsumptionInPotential',
    dataField: 'cruiseControlConsumptionInPotential',
    labelId: 'electricCruiseControlConsumptionInPotential',
    formatter: electricConsumptionPerDistanceSignal.format,
    unit: electricConsumptionPerDistanceSignal.unit,
});

export const noCruiseControlConsumptionInPotential = createColumn({
    key: 'noCruiseControlConsumptionInPotential',
    dataField: 'noCruiseControlConsumptionInPotential',
    labelId: 'noCruiseControlConsumptionInPotential',
    formatter: consumptionPerDistanceSignal.format,
    unit: consumptionPerDistanceSignal.unit,
});

export const electricNoCruiseControlConsumptionInPotential = createColumn({
    key: 'electricNoCruiseControlConsumptionInPotential',
    dataField: 'noCruiseControlConsumptionInPotential',
    labelId: 'electricNoCruiseControlConsumptionInPotential',
    formatter: electricConsumptionPerDistanceSignal.format,
    unit: electricConsumptionPerDistanceSignal.unit,
});

export const operatingFuelConsumption = createColumn({
    key: 'operatingFuelConsumption',
    dataField: 'operatingFuelConsumption',
    labelId: 'operatingFuelConsumption',
    formatter: consumptionPerDistanceSignal.format,
    unit: consumptionPerDistanceSignal.unit,
});

export const electricAverageOperatingConsumption = createColumn({
    key: 'electricAverageOperatingConsumption',
    dataField: 'electricAverageOperatingConsumption',
    labelId: 'operatingFuelConsumption',
    formatter: electricConsumptionPerDistanceSignal.format,
    unit: electricConsumptionPerDistanceSignal.unit,
});

export const drivingConsumption = createColumn({
    key: 'drivingConsumption',
    dataField: 'drivingConsumption',
    labelId: 'drivingConsumption',
    formatter: consumptionPerDistanceSignal.format,
    unit: consumptionPerDistanceSignal.unit,
});

export const electricAverageDrivingConsumption = createColumn({
    key: 'electricAverageDrivingConsumption',
    dataField: 'electricAverageDrivingConsumption',
    labelId: 'drivingConsumption',
    formatter: electricConsumptionPerDistanceSignal.format,
    unit: electricConsumptionPerDistanceSignal.unit,
});

export const distanceFuelConsumption = createColumn({
    key: 'distanceFuelConsumption',
    dataField: 'distanceFuelConsumption',
    labelId: 'distanceFuelConsumption',
    formatter: distancePerConsumptionSignal.format,
    unit: distancePerConsumptionSignal.unit,
});

export const electricAverageOperatingConsumptionRange = createColumn({
    key: 'electricAverageOperatingConsumptionRange',
    dataField: 'electricAverageOperatingConsumptionRange',
    labelId: 'distanceFuelConsumption',
    formatter: distancePerElectricConsumptionSignal.format,
    unit: distancePerElectricConsumptionSignal.unit,
});

export const idlingConsumption = createColumn({
    key: 'idlingConsumption',
    dataField: 'idlingConsumption',
    labelId: 'idlingConsumption',
    formatter: consumptionPerTimeSignal.format,
    unit: consumptionPerTimeSignal.unit,
});

export const electricAverageIdlingConsumption = createColumn({
    key: 'electricAverageIdlingConsumption',
    dataField: 'electricAverageIdlingConsumption',
    labelId: 'electricAverageIdlingConsumption',
    formatter: electricConsumptionPerTimeSignal.format,
    unit: electricConsumptionPerTimeSignal.unit,
});

export const fuelAndElectricAverageIdlingConsumption = createColumn({
    key: 'electricAverageIdlingConsumption',
    dataField: 'electricAverageIdlingConsumption',
    labelId: 'fuelAndElectricAverageIdlingConsumption',
    formatter: electricConsumptionPerTimeSignal.format,
    unit: electricConsumptionPerTimeSignal.unit,
});

export const fuelEfficiency = createColumn({
    key: 'fuelEfficiency',
    dataField: 'fuelEfficiency',
    labelId: 'fuelEfficiency',
    formatter: fuelEfficiencySignal.format,
    unit: fuelEfficiencySignal.unit,
});

export const electricEfficiency = createColumn({
    key: 'electricEfficiency',
    dataField: 'electricEfficiency',
    labelId: 'energyEfficiency',
    formatter: compose<ReactNode>(withUnit(ELECTRIC_EFFICIENCY), formatTwoOrThreeDecimals),
    unit: ELECTRIC_EFFICIENCY,
});

export const electricRecuperation = createColumn({
    key: 'electricRecuperation',
    dataField: 'electricRecuperation',
    labelId: 'electricRecuperation',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR), formatOneDigitNumber),
    unit: KILOWATTS_HOUR,
});

export const DrivetrainConsumption = createColumn({
    key: 'drivetrainConsumption',
    dataField: 'drivetrainConsumption',
    labelId: 'drivetrainConsumption',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR), formatOneDigitNumber),
    unit: KILOWATTS_HOUR,
});

export const EptoConsumption = createColumn({
    key: 'eptoConsumption',
    dataField: 'eptoConsumption',
    labelId: 'eptoConsumption',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR), formatOneDigitNumber),
    unit: KILOWATTS_HOUR,
});

export const BatterylossesConsumption = createColumn({
    key: 'batterylossesConsumption',
    dataField: 'batterylossesConsumption',
    labelId: 'batterylossesConsumption',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR), formatOneDigitNumber),
    unit: KILOWATTS_HOUR,
});

export const AircompressorConsumption = createColumn({
    key: 'aircompressorConsumption',
    dataField: 'aircompressorConsumption',
    labelId: 'aircompressorConsumption',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR), formatOneDigitNumber),
    unit: KILOWATTS_HOUR,
});

export const CoolingConsumption = createColumn({
    key: 'coolingConsumption',
    dataField: 'coolingConsumption',
    labelId: 'coolingConsumption',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR), formatOneDigitNumber),
    unit: KILOWATTS_HOUR,
});

export const HvacConsumption = createColumn({
    key: 'hvacConsumption',
    dataField: 'hvacConsumption',
    labelId: 'hvacConsumption',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR), formatOneDigitNumber),
    unit: KILOWATTS_HOUR,
});

export const VpowersupplyConsumption = createColumn({
    key: 'vpowersupplyConsumption',
    dataField: 'vpowersupplyConsumption',
    labelId: 'vpowersupplyConsumption',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR), formatOneDigitNumber),
    unit: KILOWATTS_HOUR,
});

export const TotalConsumption = createColumn({
    key: 'totalPowerConsumption',
    dataField: 'totalPowerConsumption',
    labelId: 'totalPowerConsumption',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR), formatOneDigitNumber),
    unit: KILOWATTS_HOUR,
});

export const electricRecuperationPerKm = createColumn({
    key: 'electricRecuperationPerKm',
    dataField: 'electricRecuperationPerKm',
    labelId: 'electricRecuperationPerKm',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR_PER_KM), formatOneDigitNumber),
    unit: KILOWATTS_HOUR_PER_KM,
});

export const DrivetrainPerKm = createColumn({
    key: 'drivetrainConsumptionPerKm',
    dataField: 'drivetrainConsumptionPerKm',
    labelId: 'drivetrainConsumptionPerKm',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR_PER_KM), formatOneDigitNumber),
    unit: KILOWATTS_HOUR_PER_KM,
});

export const EptoPerKm = createColumn({
    key: 'eptoConsumptionPerKm',
    dataField: 'eptoConsumptionPerKm',
    labelId: 'eptoConsumptionPerKm',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR_PER_KM), formatOneDigitNumber),
    unit: KILOWATTS_HOUR_PER_KM,
});

export const BatterylossesPerKm = createColumn({
    key: 'batterylossesConsumptionPerKm',
    dataField: 'batterylossesConsumptionPerKm',
    labelId: 'batterylossesConsumptionPerKm',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR_PER_KM), formatOneDigitNumber),
    unit: KILOWATTS_HOUR_PER_KM,
});

export const AircompressorPerKm = createColumn({
    key: 'aircompressorConsumptionPerKm',
    dataField: 'aircompressorConsumptionPerKm',
    labelId: 'aircompressorConsumptionPerKm',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR_PER_KM), formatOneDigitNumber),
    unit: KILOWATTS_HOUR_PER_KM,
});

export const CoolingPerKm = createColumn({
    key: 'coolingConsumptionPerKm',
    dataField: 'coolingConsumptionPerKm',
    labelId: 'coolingConsumptionPerKm',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR_PER_KM), formatOneDigitNumber),
    unit: KILOWATTS_HOUR_PER_KM,
});

export const HvacPerKm = createColumn({
    key: 'hvacConsumptionPerKm',
    dataField: 'hvacConsumptionPerKm',
    labelId: 'hvacConsumptionPerKm',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR_PER_KM), formatOneDigitNumber),
    unit: KILOWATTS_HOUR_PER_KM,
});

export const VpowersupplyPerKm = createColumn({
    key: 'vpowersupplyConsumptionPerKm',
    dataField: 'vpowersupplyConsumptionPerKm',
    labelId: 'vpowersupplyConsumptionPerKm',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR_PER_KM), formatOneDigitNumber),
    unit: KILOWATTS_HOUR_PER_KM,
});

export const EnergyEfficiencyPerKm = createColumn({
    key: 'energyEfficiencyPerKm',
    dataField: 'energyEfficiencyPerKm',
    labelId: 'energyEfficiencyPerKm',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR_PER_KM), formatOneDigitNumber),
    unit: KILOWATTS_HOUR_PER_KM,
});

export const electricRecuperationKmPerKwh = createColumn({
    key: 'electricRecuperationKmPerKwh',
    dataField: 'electricRecuperationKmPerKwh',
    labelId: 'electricRecuperationKmPerKwh',
    formatter: compose<ReactNode>(withUnit(KILOMETERS_PER_KILOWATTS_HOUR), formatOneDigitNumber),
    unit: KILOMETERS_PER_KILOWATTS_HOUR,
});

export const electricRecuperationPerHour = createColumn({
    key: 'electricRecuperationPerHour',
    dataField: 'electricRecuperationPerHour',
    labelId: 'electricRecuperationPerHour',
    formatter: compose<ReactNode>(withUnit(KILOWATTS_HOUR_PER_HOUR), formatOneDigitNumber),
    unit: KILOWATTS_HOUR_PER_HOUR,
});
