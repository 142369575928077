export const FORESIGHTED_REQUEST_ATTRIBUTES = [
    'acceleration_pedal_count_with_cc',
    'acceleration_pedal_distance_with_cc',
    'acceleration_pedal_time_with_cc',
    'active_coasting_count',
    'active_coasting_distance',
    'active_coasting_time',
    'braking_rating',
    'coasting_rating',
    'cruise_control_consumption_in_potential',
    'cruise_control_distance_in_potential',
    'cruise_control_potential_distance',
    'cruise_control_time_in_potential',
    'driver_ids',
    'emergency_brake_count',
    'kickdown_count',
    'kickdown_distance',
    'kickdown_time',
    'motor_brake_count',
    'motor_brake_distance',
    'motor_brake_time',
    'no_cruise_control_consumption_in_potential',
    'no_cruise_control_distance_in_potential',
    'no_cruise_control_time_in_potential',
    'overspeed_distance',
    'overspeed_rating',
    'retarder_count',
    'retarder_distance',
    'retarder_time',
    'service_brake_count',
    'service_brake_distance',
    'service_brake_time',
    'vehicle_ids',
];

export const HARSH_KPIS_ATTRIBUTES = [
    'harsh_acceleration_medium_count',
    'harsh_acceleration_medium_time',
    'harsh_acceleration_hard_count',
    'harsh_acceleration_hard_time',
    'harsh_braking_medium_count',
    'harsh_braking_medium_time',
    'harsh_braking_hard_count',
    'harsh_braking_hard_time',
    'harsh_acceleration_rating',
    'harsh_braking_rating',
];

export const EXCESSIVE_IDLING_KPIS_ATTRIBUTES = [
    'excessive_idling_time',
    'acceptable_idling_time',
    'excessive_idling_rating',
    'total_idling_count',
    'excessive_idling_count',
];

export const ELECTRIC_RECUPERATION_KPIS = ['electric_recuperation'];

export const ELECTRIC_KPIS_ATTRIBUTES = [
    'electric_average_idling_consumption',
    'electric_average_driving_consumption',
    'electric_operating_consumption',
    'electric_average_operating_consumption',
    'electric_average_operating_consumption_range',
    ...ELECTRIC_RECUPERATION_KPIS,
];

export const FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V3 = [
    'cruise_control_rating_v3',
    'acceleration_pedal_rating',
    'foresighted_driving_percentage_v3',
];

export const FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V2 = [
    'acceleration_pedal_rating_v2',
    'cruise_control_rating_v2',
    'foresighted_driving_percentage_v2',
];

export const FORESIGHTED_REQUEST_TRUE_DRIVING_RATINGS_KPIS_ATTRIBUTES = [
    ...ELECTRIC_RECUPERATION_KPIS,
    'electric_recuperation_rating',
];

export const FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V3_WITHOUT_CC = [
    ...FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V3,
    'foresighted_driving_percentage_without_cc_v2',
];

export const FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V2_WITHOUT_CC = [
    ...FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V2,
    'foresighted_driving_percentage_without_cc_v2',
];

export const FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V1 = [
    'cruise_control_rating',
    'acceleration_pedal_rating',
    'foresighted_driving_percentage',
];

export const FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V1_WITHOUT_CC = [
    ...FORESIGHTED_REQUEST_ATTRIBUTES_OVERTAKE_V1,
    'foresighted_driving_percentage_without_cc',
];

export const FORESIGHTED_REQUEST_ATTRIBUTES_V1 = [
    'coasting_distance',
    'coasting_time',
    'passive_coasting_time',
    'passive_coasting_distance',
    'passive_coasting_count',
];

export const FORESIGHTED_REQUEST_ATTRIBUTES_V2 = [
    ...FORESIGHTED_REQUEST_ATTRIBUTES_V1,
    'foresighted_driving_percentage_without_cc_v2',
];
