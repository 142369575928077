import './stylesVehiclesInfoTabs.less';

import Box from '@components/Box';
import DataTab from '@rio-cloud/rio-uikit/DataTab';
import DataTabs from '@rio-cloud/rio-uikit/DataTabs';
import React, { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface rowsData {
    rows: { electricColumns: Array<Array<ReactNode>>; fuelColumns: Array<Array<ReactNode>> };
}

const fuelId = 'fuelVehicles';
const electricId = 'electricVehicles';

const VehiclesInfoTabs = ({ rows }: rowsData) => {
    const [tabKey, setTabKey] = useState('fuelVehicles');

    const tabsData = [
        {
            tabKey: fuelId,
            title: { text: 'fuelVehicles.tab', value: 'filling-station' },
            content: rows.fuelColumns,
        },
        {
            tabKey: electricId,
            title: { text: 'electricVehicles.tab', value: 'flash' },
            content: rows.electricColumns,
        },
    ];

    const getTabActiveClassName = (activeTab: string) => {
        const baseClass =
            'bg-white opacity-100 margin-bottom--1 margin-top-1 padding-y-5 rounded-tl-md rounded-tr-md rounded-top-large border';

        if (activeTab === fuelId) {
            return `${baseClass} border-right-none`;
        }
        if (activeTab === electricId) {
            return `${baseClass} border-left-none`;
        }

        return baseClass;
    };

    const tabClass =
        'bg-lighter opacity-50 height-70 display-flex align-items-center justify-content-center rounded-top-large rounded-bottom-large border';

    return (
        <div className="tabs-container">
            <DataTabs
                activeKey={tabKey}
                bordered={false}
                disableTransition={false}
                onSelectTab={newTabKey => setTabKey(newTabKey)}
                tabsWrapperClassName="bg-white padding-0"
                tabClassName={tabClass}
                tabHoverClassName={tabClass}
                tabActiveClassName={getTabActiveClassName(tabKey)}
                tabContentClassName="padding-0 padding-bottom-10"
                arrowClassName="d-none"
            >
                {tabsData.map(tab => (
                    <DataTab
                        className="padding-0 border-bottom-none "
                        key={tab.tabKey}
                        tabKey={tab.tabKey}
                        data-test={'data-tab-sidebar-' + tab.tabKey}
                        title={<Title text={tab.title.text} value={tab.title.value} />}
                    >
                        <div className="margin-10 margin-top-0">
                            <div className="display-flex text-size-18 flex-row align-items-center">
                                <span className="margin-right-10">
                                    <span className="rioglyph rioglyph-tachograph"></span>
                                </span>
                                <span className="text-size-18">
                                    <FormattedMessage id="operatingData.label" />
                                </span>
                            </div>
                            <Box rows={tab.content} hasBorder={true} />
                        </div>
                    </DataTab>
                ))}
            </DataTabs>
        </div>
    );
};

export default VehiclesInfoTabs;

export const Title = ({ text, value }: { text: string; value: React.ReactNode }) => (
    <div className="text-size-18 display-flex align-items-center justify-content-center height-60">
        <span className={`rioglyph rioglyph-${value}`} />
        <div className="padding-left-10">
            <FormattedMessage id={text} />
        </div>
    </div>
);
