import { FormatDateOptions } from '@formatjs/intl/src/types';
import configureGetCreateTimeInterval from '@utils/timeInterval';
import moment from 'moment';

import { configureReporting } from '../../setup/errorReporting';
import { DateRange } from '../../types';

const { captureException } = configureReporting(window, import.meta.env);

const UTC_MIDNIGHT = (): number => {
    const now = new Date();
    const midnightUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1));
    return midnightUTC.getTime();
};

export const saveFlagToLocalStorage = (key: string, value: boolean) => {
    try {
        return localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        captureException(e as Error);
    }
};

export const saveFleetFlagToLocalStorage = (key: string, doNotShowAgain?: boolean) => {
    try {
        const data = {
            value: true,
            expiration: UTC_MIDNIGHT(),
            doNotShowAgain: doNotShowAgain,
        };

        return localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
        captureException(e as Error);
    }
};

export const getFlagFromLocalStorage = (key: string): boolean => {
    try {
        const item = localStorage.getItem(key);
        if (!item) return false;

        const localStorageItem = JSON.parse(item);
        if (!localStorageItem) return false;

        return Boolean(localStorageItem);
    } catch (error) {
        captureException(error as Error);
        return false;
    }
};

export const getFleetFlagFromLocalStorage = (key: string): boolean => {
    try {
        const item = localStorage.getItem(key);
        if (!item) return false;

        const localStorageItem = JSON.parse(item);
        if (!localStorageItem || typeof localStorageItem !== 'object') return false;

        const { value, expiration, doNotShowAgain } = localStorageItem as {
            value?: boolean;
            expiration?: number;
            doNotShowAgain?: boolean;
        };

        if (typeof expiration === 'number' && Date.now() > expiration) {
            if (!doNotShowAgain) {
                localStorage.removeItem(key);
                return false;
            }
        }

        return Boolean(value);
    } catch (error) {
        captureException(error as Error);
        return false;
    }
};

export const steps = configureGetCreateTimeInterval<moment.unitOfTime.Diff>({
    days: 'day',
    weeks: 'weeks',
    months: 'months',
    quarters: 'quarters',
    years: 'year',
});

export const formatSegmentDescriptionLong = (
    formatDate: (d: Date, options: FormatDateOptions) => string,
    formatMessage: ({ id }: { id: string }) => string
) =>
    configureGetCreateTimeInterval({
        days: (d: Date) =>
            formatDate(d, {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
            }),
        weeks: (d: Date) =>
            `${moment(d).week()} ${formatMessage({ id: 'time_interval_calendar_week_short' })} ${formatDate(d, {
                year: 'numeric',
            })}`,
        months: (d: Date) =>
            formatDate(d, {
                year: 'numeric',
                month: 'long',
            }),
        quarters: (d: Date) =>
            `${moment(d).quarter()} ${formatMessage({ id: 'time_interval_quarter_short' })} ${formatDate(d, {
                year: 'numeric',
            })}`,
        years: (d: Date) =>
            formatDate(d, {
                year: 'numeric',
            }),
    });

export const segmentInterval = (dateRange: DateRange) => {
    const step = steps(dateRange);
    const momentRange = { start: moment(dateRange.start), end: moment(dateRange.end) };

    return (date: Date): DateRange => {
        const momentStart = moment(date);
        const momentEnd = momentStart
            .clone()
            .add(1, step)
            .subtract(1, 'second');

        return {
            start: moment.max(momentStart, momentRange.start).toDate(),
            end: moment.min(momentEnd, momentRange.end).toDate(),
        };
    };
};
