import { mean } from 'd3-array';
import _ from 'lodash';
import { Fragment } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, FormattedTime } from 'react-intl';

export const plainAverage = (values: number[]) => mean(values);
export const formatIntegerNumber = (value?: number | '-') =>
    !_.isUndefined(value) && value !== '-' ? <FormattedNumber value={value} maximumFractionDigits={0} /> : '-';
export const formatConditionalDigitNumber = (value?: string | number | undefined) =>
    !_.isUndefined(value) && value !== '-' && typeof value !== 'string' ? (
        <FormattedNumber value={value} maximumFractionDigits={value < 10 ? 3 : 1} />
    ) : (
        '-'
    );

export const formatOneDigitNumber = (value?: number | '-') =>
    !_.isUndefined(value) && value !== '-' ? <FormattedNumber value={value} maximumFractionDigits={1} /> : '-';

export const formatTwoOrThreeDecimals = (value?: number | '-') => {
    if (_.isUndefined(value) || value === '-') return '-';

    const decimalPart = (value % 1).toFixed(3);
    const [, first, second] = decimalPart.match(/^0\.(\d)(\d)(\d)$/) || [];

    return <FormattedNumber value={value} maximumFractionDigits={first === '0' && second === '0' ? 3 : 2} />;
};

export const weightAverageByMileage = (values: number[]) => getWeightedAverageBy(values);

export const getWeightedAverageBy = (values: number[]) => {
    const weightingValues: number[] = [];
    const weightedValues = _.sum(values.map((v, i) => v * weightingValues[i]));
    const weightedSum = _.sum(weightingValues);
    return weightedSum ? weightedValues / weightedSum : 0;
};

export const withUnit = (unit: string) => (value: string | number) => {
    if (value !== '-') {
        return (
            <Fragment>
                {value} <FormattedMessage id={`unit.${unit}`} />
            </Fragment>
        );
    }
    return value;
};

export const formatDateTime = ({ value }: { value?: string | number | Date }) => {
    if (value) {
        return (
            <Fragment>
                <FormattedDate value={value} /> <FormattedTime value={value} />
            </Fragment>
        );
    }
    return <></>;
};

export const formatToValueOrZero = (value?: number | '-' | null) => (_.isNil(value) || value === '-' ? '0' : value);
