import { TypeOf } from 'io-ts';

import { fetchGetWithJSONResponseCyclicWithPagination } from './api';
import { decodeGroups, TagApiResponseItem, TagApiResponseType } from './decoder/decodeGroups';
import mergeResponses from './helpers/mergeResponses';

export async function fetchGroups(baseUrlTags: string, authToken: string) {
    const rawTags = await fetchGetWithJSONResponseCyclicWithPagination<TagApiResponseType>(
        baseUrlTags,
        `tags`,
        authToken
    );
    const merged = mergeResponses<TypeOf<typeof TagApiResponseItem>, TagApiResponseType>(rawTags);

    return decodeGroups(merged);
}
